import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import NavBannerItem from '../navBannerItem/NavBannerItem'
import './navBanner.scss'

const NavBanner = () => {
  const { categories, menuIsOpen } = useContext(AppContext)

  return (
    <div className={menuIsOpen ? 'nav-banner nav-banner--open' : 'nav-banner'}>
      <nav className="nav-banner__nav">
        <ul className="nav-banner__list">
          {categories
            ?.filter((item) => item.parent === 0)
            .map((item, i) => (
              <NavBannerItem key={i} category={item} />
            ))}
        </ul>
      </nav>
    </div>
  )
}

export default NavBanner
