import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Banner from "./components/banner/Banner";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Wrapper from "./components/wrapper/Wrapper";
import Routes from "./components/routes/Routes.jsx";
import ScrollTop from "./components/scrollTop/ScrollTop";
import useIsScreenSize from "./hooks/useIsScreenSize";
import { Suspense } from "react";
import RoutesLoader from "./RoutesLoader/RoutesLoader";
import Providers from "./components/providers/Providers";

const App = () => {
  const isMobile = useIsScreenSize(940);
  return (
    <div className="app">
      <Router>
        <Suspense fallback={<RoutesLoader />}>
          <Providers>
            <Header />
            <Banner />
            <Wrapper>
              <Routes />
              {isMobile && <ScrollTop />}
            </Wrapper>
            <Footer />
          </Providers>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
