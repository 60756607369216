import './loader.scss'
import ScaleLoader from 'react-spinners/ScaleLoader'

const Loader = ({ className, height, width }) => {
  return (
    <div className={`${className} loader`}>
      <ScaleLoader height={height} width={width} color="#741b47" />
    </div>
  )
}

export default Loader
