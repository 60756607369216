import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { I18nContext } from '../../i18n'
import { useContext, useEffect } from 'react'
import routes from './data/routes.js'

const Routes = () => {
  const { langCode } = useContext(I18nContext)
  const supportedLanguages = ['en', 'fr', 'es']
  const localePath = `:locale(${supportedLanguages.join('|')})`

  const history = useHistory()

  useEffect(() => {
    const path = history.location.pathname.split('/').slice(2)
    history.push(`/${langCode}/${path.join('/')}`)
  }, [langCode, history]) // change de langcode of the hash when user actualise webpage and no localstorage is found

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to={`${langCode}`} />
        }}
      />
      {routes.map((route, i) => (
        <Route
          key={i}
          exact
          path={`/${localePath}${route.path}`}
          component={route.component}
        />
      ))}
    </Switch>
  )
}

export default Routes
