import { forwardRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { I18nContext } from "../../i18n";

import "./lang-dropdown.scss";

const LangDropDown = forwardRef((props, ref) => {
  const { dispatch, translate, langCode } = useContext(I18nContext);
  const { setCurrentLanguage } = useContext(AppContext);

  const history = useHistory();

  const setHash = (lang, currentLang) => {
    if (lang === currentLang) return;
    history.push("/");
  };

  useEffect(() => {
    setCurrentLanguage(langCode);
  }, [langCode, setCurrentLanguage]);

  const onLanguageSelect = (e) => {
    props.closeDropDown();
    const choosenLang = e.target.dataset.lang;
    setHash(choosenLang, langCode); // change the hash with choosen language
    localStorage.setItem("langCode", choosenLang); // sate language set into local storage
    setCurrentLanguage(choosenLang);
    dispatch({ type: "setLanguage", payload: choosenLang }); // set language to display text content in choosen language
  };

  return (
    <div ref={ref} className="lang-dropdown">
      <div className="lang-dropdown-inner">
        <li onClick={onLanguageSelect} data-lang="fr" className="lang-dropdown-item">
          {translate("header").french}
        </li>

        <li onClick={onLanguageSelect} data-lang="en" className="lang-dropdown-item">
          {translate("header").english}
        </li>
        <li onClick={onLanguageSelect} data-lang="es" className="lang-dropdown-item">
          {translate("header").spanish}
        </li>
      </div>
    </div>
  );
});

export default LangDropDown;
