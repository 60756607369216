import { useContext, useEffect, useRef, useState } from 'react'
import './search.scss'
import { BiSearchAlt2 } from 'react-icons/bi'
import { I18nContext } from '../../i18n'
import { searchPosts } from '../../dataProvider/posts'
import { SearchContext } from '../../context/SearchContext'
import SearchResults from '../searchResults/SearchResults'
import { useHistory, useLocation } from 'react-router-dom'
import useIsScreenSize from '../../hooks/useIsScreenSize'
import { AppContext } from '../../context/AppContext'
import useMousePosition from '../../hooks/useMouseMouve'

const Search = ({ open = false }) => {
  const {
    input,
    results,
    setInput,
    setResults,
    setDisplaySearch,
    displaySearch,
    isHoverSearch,
    setIsHoverSearch,
  } = useContext(SearchContext)

  const { currentTag } = useContext(AppContext)
  const { translate, langCode } = useContext(I18nContext)

  const [page, setPage] = useState()
  const location = useLocation()
  const screenSize = useIsScreenSize(780)
  const history = useHistory()

  useEffect(() => {
    const page = location.pathname.split('/')[2]
    setPage(page)
  }, [location])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (input === '') return
    history.push(`/${langCode}/search?${input}`)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
    if (e.target.value === '') {
      setDisplaySearch(false)
      setResults({ message: 'x' })
      return
    }
    setDisplaySearch(true)
    const query = e.target.value
    searchPosts(query, 5, 1, currentTag).then((res) => setResults(res))
  }
  const inputRef = useRef(null)

  const handleHoverIcon = (e) => {
    inputRef.current.focus()
    setIsHoverSearch(!isHoverSearch)
  }

  const mousePosition = useMousePosition()

  useEffect(() => {
    if (mousePosition.y > window.innerHeight - 50 && open === false) {
      setIsHoverSearch(false)
      inputRef.current.blur()
      setResults({ message: 'x' })
    }
  }, [mousePosition, setIsHoverSearch, setResults, setInput, open])

  return (
    <div
      className={
        open === true
          ? 'search search--open'
          : isHoverSearch
          ? 'search search--open'
          : 'search'
      }
    >
      <form
        onSubmit={handleSubmit}
        className={
          open === true
            ? 'search-form search-form--open'
            : isHoverSearch
            ? 'search-form search-form--open'
            : 'search-form'
        }
      >
        <label className="hidden" htmlFor="search">
          Search
        </label>
        <input
          id="search"
          onChange={handleChange}
          value={input}
          ref={inputRef}
          className={
            open === true
              ? 'search-form__input search-form__input--open'
              : isHoverSearch
              ? 'search-form__input search-form__input--open'
              : 'search-form__input'
          }
          placeholder={
            open
              ? translate('header').search
              : isHoverSearch
              ? translate('header').search
              : ''
          }
        />
        {displaySearch && !results?.message && page !== 'search' && (
          <SearchResults />
        )}
        <BiSearchAlt2
          onClick={handleHoverIcon}
          className={'search-form__icon'}
          size={screenSize ? 26 : 22}
        />
      </form>
    </div>
  )
}

export default Search
