import Config from './config'

export const getTags = async () => {
  try {
    const res = await fetch(`${Config.api.url}tags`)
    return await res.json()
  } catch (err) {
    console.error(err)
  }
}
