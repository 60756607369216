import React, { useReducer } from "react";

import EN from "./en.json";
import FR from "./fr.json";
import ES from "./es.json";

const translations = {
  en: EN,
  fr: FR,
  es: ES,
};

const getTranslate = (langCode) => (key) => translations[langCode][key];

const initialState = {
  langCode:
    localStorage.getItem("langCode") ||
    navigator.language.split("-")[0] ||
    navigator.userLanguage.split("-")[0] ||
    "en",
  translate: getTranslate(
    localStorage.getItem("langCode") ||
      navigator.language.split("-")[0] ||
      navigator.userLanguage.split("-")[0] ||
      "en"
  ),
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setLanguage":
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
