import { createContext, useState } from "react";

export const SearchContext = createContext();

const { Provider } = SearchContext;

export const SearchProvider = (props) => {
  const [input, setInput] = useState("");
  const [results, setResults] = useState({ message: "x" }); // setting result to hash to avoid displaying search result at first render
  const [displaySearch, setDisplaySearch] = useState();
  const [isHoverSearch, setIsHoverSearch] = useState(false);

  const value = {
    input,
    results,
    setInput,
    setResults,
    displaySearch,
    setDisplaySearch,
    isHoverSearch,
    setIsHoverSearch,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
