import { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import Logo from '../logo/Logo'
import Wrapper from '../wrapper/Wrapper'
import './banner.scss'
import NavBanner from './NavBanner/NavBanner'

const Banner = () => {
  const { menuIsOpen } = useContext(AppContext)

  useEffect(() => {
    const body = document.querySelector('body')
    if (menuIsOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }
  }, [menuIsOpen])

  return (
    <div className={menuIsOpen ? 'banner banner--open' : 'banner'}>
      <Wrapper>
        <Logo />
        <NavBanner />
      </Wrapper>
    </div>
  )
}

export default Banner
