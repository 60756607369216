import React, { useEffect, useState } from 'react'
import { GoArrowSmallRight } from 'react-icons/go'
import Loader from '../Loader/Loader'
import './fader.scss'

const Fader = ({ text, tag, className, onClick, animate, arrow }) => {
  const [textToRender, setTextToRender] = useState()
  const [toggleClass, setToggleClass] = useState(false)

  useEffect(() => {
    setToggleClass(false)
    setTextToRender(text)
    let timer1 = setTimeout(() => {
      setToggleClass(true)
    }, 300)
    return () => clearTimeout(timer1)
  }, [text])

  if (!toggleClass && animate === true) {
    return <Loader height={10} width={2} />
  }

  if (tag === 'p' || tag === undefined) {
    return (
      <p
        onClick={() => onClick && onClick()}
        className={
          toggleClass
            ? `fader fader--active ${className}`
            : `fader ${className}`
        }
      >
        {textToRender}
        {arrow && textToRender && <GoArrowSmallRight size={22} />}
      </p>
    )
  }
  if (tag === 'h2') {
    return (
      <h2
        onClick={() => onClick && onClick()}
        className={
          toggleClass
            ? `fader fader--active ${className}`
            : `fader ${className}`
        }
      >
        {textToRender}
        {arrow && textToRender && <GoArrowSmallRight size={22} />}
      </h2>
    )
  }
  return {}
}

export default Fader
