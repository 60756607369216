import { lazy } from 'react'

const Category = lazy(() => import('../../../pages/category/Category'))
const Home = lazy(() => import('../../../pages/home/Home'))
const About = lazy(() => import('../../../pages/about/About.jsx'))
const Article = lazy(() => import('../../../pages/article/ArticlePage'))
const SearchPage = lazy(() => import('../../../pages/search/SearchPage'))

const routes = [
  { path: '/', component: Home },
  { path: '/article/:name', component: Article },
  { path: '/category/:name', component: Category },
  { path: '/about', component: About },
  { path: '/search', component: SearchPage },
]

export default routes
