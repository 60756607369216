import Config from './config'

export const getAllPostFromCategoryId = async (id, tags, per_page) => {
  if (id === undefined || tags === undefined) return []
  try {
    const res = await fetch(
      `${Config.api.url}posts/category/${id}?tags=${tags}&per_page=${per_page}`,
    )
    return await res.json()
  } catch (err) {
    return console.error(err)
  }
}

export const getPostBySlug = async (slug, tags) => {
  try {
    const res = await fetch(`${Config.api.url}posts?slug=${slug}&tags=${tags}`)
    return await res.json()
  } catch (err) {
    return console.error(err)
  }
}

export const searchPosts = async (query, per_page, page, tags) => {
  const search_query = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  try {
    const res = await fetch(
      `${Config.api.url}posts?search=${search_query}&per_page=${per_page}&page=${page}&_embed=true&tags=${tags}`,
    )
    return await res.json()
  } catch (err) {
    return console.error(err)
  }
}

export const getPostById = async (id) => {
  try {
    const res = await fetch(`${Config.api.url}posts/${id}`)
    return await res.json()
  } catch (err) {
    return console.error(err)
  }
}

export const getStickyPosts = async (langCode, per_page) => {
  try {
    const res = await fetch(
      `${Config.api.url}posts?_embed=true&sticky=true&tags=${langCode}&per_page=${per_page}`,
    )
    return await res.json()
  } catch (error) {
    return console.error(error)
  }
}
