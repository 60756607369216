import { useContext } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { I18nContext } from '../i18n'
import './routesLoader.scss'

const RoutesLoader = () => {
  const { translate } = useContext(I18nContext)

  return (
    <div className="routes-loader">
      <ScaleLoader color="white" />
      <p className="routes-loader__text">{translate('loadingPage')}</p>
    </div>
  )
}

export default RoutesLoader
