import "./social.scss";
import { useContext } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiFacebookCircleFill } from "react-icons/ri";
import { I18nContext } from "../../i18n";
import Fader from "../fader/Fader";

const Social = () => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="social">
      <Fader text={translate("header").social} className="social__text" />
      <div className="social__icons">
        <a href="https://www.facebook.com/webtop.blog" target={"_blank"} rel="noreferrer">
          <RiFacebookCircleFill className="social__icon icon--twitter" size={25} color="white" />
        </a>
        <a href="https://twitter.com/WebtopB" target="_blank" rel="noreferrer">
          <AiFillTwitterCircle className="social__icon icon--facebook" size={25} color="white" />
        </a>
      </div>
    </div>
  );
};

export default Social;
