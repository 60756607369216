import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import "./scrollTop.scss";

const ScrollTop = () => {
  return (
    <div
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      className="scrollTop">
      <AiOutlineVerticalAlignTop color="white" size={25} />
    </div>
  );
};

export default ScrollTop;
