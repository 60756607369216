import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { I18nContext } from "../../i18n";
import "./searchResultsItem.scss";
import Logo from "../../images/logo/logo-icon.svg";
import { SearchContext } from "../../context/SearchContext";

const SearchResultsItem = ({ result }) => {
  const { langCode } = useContext(I18nContext);
  const { setDisplaySearch, setInput, setResults } = useContext(SearchContext);

  const history = useHistory();

  const handleClick = () => {
    setDisplaySearch(false);
    setInput("");
    setResults({ message: "x" });
    history.push(`/${langCode}/article/${result?.slug}`);
  };

  return (
    <li onClick={() => handleClick()} className="search-results-item">
      <img className="search-results-item__icon" src={Logo} alt="icon" />
      <p>{result?.title?.rendered}.</p>
    </li>
  );
};

export default SearchResultsItem;
