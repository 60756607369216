import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../context/AppContext'
import { I18nContext } from '../../../i18n'
import Fader from '../../fader/Fader'
import './navBannerItem.scss'

const NavBannerItem = ({ category }) => {
  const { setCategorySelected, categorySelected, setMenuIsOpen } = useContext(
    AppContext,
  )
  const { translate, langCode } = useContext(I18nContext)

  const handleClick = () => {
    setMenuIsOpen(false)
    setCategorySelected(category)
  }

  return (
    <li className="navBanner-item">
      <Link
        to={`/${langCode}/category/${category.slug}`}
        onClick={handleClick}
        className={
          categorySelected?.name === category.name
            ? `navBanner-item navBanner-item--selected `
            : `navBanner-item`
        }
      >
        <Fader
          animate={true}
          tag="p"
          text={translate('categories')?.[category.slug]}
        />
      </Link>
    </li>
  )
}

export default NavBannerItem
