import { useContext } from 'react'
import './header.scss'
import Wrapper from '../wrapper/Wrapper'
import Search from '../search/Search'
import Social from '../social/Social'
import useIsScreenSize from '../../hooks/useIsScreenSize'
import { HiMenu, HiX } from 'react-icons/hi'
import { AppContext } from '../../context/AppContext'
import HeaderLang from '../headerLang/HeaderLang'

const Header = () => {
  const { setMenuIsOpen, menuIsOpen } = useContext(AppContext)
  const screenIsSmall = useIsScreenSize(780)

  const handleClick = () => setMenuIsOpen(!menuIsOpen)

  return (
    <header className="header">
      {screenIsSmall &&
        (menuIsOpen ? (
          <HiX onClick={handleClick} className="header__hamb" size={26} />
        ) : (
          <HiMenu onClick={handleClick} className="header__hamb" size={26} />
        ))}
      <Wrapper>
        <Social />
        <HeaderLang />
        <Search />
      </Wrapper>
    </header>
  )
}

export default Header
