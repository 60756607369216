import { AppProvider } from "../../context/AppContext";
import { SearchProvider } from "../../context/SearchContext";
import { I18nContextProvider } from "../../i18n";

const Providers = ({ children }) => {
  return (
    <AppProvider>
      <SearchProvider>
        <I18nContextProvider>{children}</I18nContextProvider>
      </SearchProvider>
    </AppProvider>
  );
};

export default Providers;
