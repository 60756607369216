import { useContext, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import useClickOutside from "../../hooks/useClickOutside";
import { I18nContext } from "../../i18n";
import Fader from "../fader/Fader";
import LangDropDown from "../lang-dropdown/LangDropDown";
import "./headerLang.scss";

const HeaderLang = () => {
  const [langIsOpen, setLangIsOpen] = useState(false);
  const { langCode } = useContext(I18nContext);
  const dropDown = useRef(null);
  const closeDropDown = () => setLangIsOpen(!langIsOpen);
  useClickOutside(dropDown, closeDropDown);

  return (
    <div className="langs">
      <Fader
        onClick={() => setLangIsOpen(!langIsOpen)}
        text={langCode.toUpperCase()}
        className={langIsOpen ? "langs__lang langs__lang--open" : "langs__lang"}
      />
      <div className="langs__lang-icon">
        <TiArrowSortedDown
          onClick={() => setLangIsOpen(!langIsOpen)}
          size={20}
          color="white"
        />
        {langIsOpen && (
          <LangDropDown ref={dropDown} closeDropDown={closeDropDown} />
        )}
      </div>
    </div>
  );
};

export default HeaderLang;
