const config = {
  api: {
    url: 'https://api-builder.sbfs.io/webtop/',
  },
  tagManager: '',
}

if (
  window.location.hostname === 'staging.webtop.blog' ||
  window.location.hostname === 'localhost'
) {
  config.api.url = 'https://api-builder-staging.sbfs.io/webtop/'
  config.tagManager = 'GTM-T655T8N';
}

if (window.location.hostname === 'webtop.blog') {
  config.api.url = 'https://api-builder.sbfs.io/webtop/'
  config.tagManager = 'GTM-TWQ98ZK'
}

module.exports = config
