import "./logo.scss";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo-icon.svg";
import { I18nContext } from "../../i18n";
import { useContext } from "react";

const Logo = () => {
	const { langCode } = useContext(I18nContext);
	return (
		<Link to={`/${langCode}`} className="logo">
			<img className="logo__img" src={logo} alt={"blog top logo"}></img>
			<div className="logo__title-container">
				<h1 className="logo__title">Webtop</h1>
				<p className="logo__subtitle">blog</p>
			</div>
		</Link>
	);
};

export default Logo;
