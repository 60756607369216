import { useEffect, useState } from "react";

const useIsScreenSize = (size) => {
	const [isScreenSize, setIsScreenSize] = useState();

	useEffect(() => {
		if (window.innerWidth < size) {
			setIsScreenSize(true);
		} else {
			setIsScreenSize(false);
		}
	}, [size]);

	return isScreenSize;
};

export default useIsScreenSize;
