import Config from './config'

export const getCategories = async () => {
  try {
    const res = await fetch(`${Config.api.url}allCategories?per_page=40`)
    const data = await res.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCategoryByID = async (id) => {
  try {
    const res = await fetch(`${Config.api.url}allCategories/${id}`)
    const data = await res.json()
    return data
  } catch (error) {
    console.error(error)
  }
}
