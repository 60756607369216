import "./searchResuts.scss";
import SearchResultsItem from "../searchResultsItem/SearchResultsItem";
import { SearchContext } from "../../context/SearchContext";
import { useContext } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { I18nContext } from "../../i18n";
import { useHistory } from "react-router-dom";

const SearchResults = () => {
  const { results, input, setDisplaySearch } = useContext(SearchContext);
  const { langCode, translate } = useContext(I18nContext);
  const history = useHistory();

  const handleClick = () => {
    setDisplaySearch(false);
    history.push(`/${langCode}/search?${input}`);
  };

  return (
    <div className="search-results">
      {results.length > 0 ? (
        results.map((item) => <SearchResultsItem key={item.id} result={item} />)
      ) : (
        <p className="search-results__no-item">
          {translate("searchPage").noResults}
        </p>
      )}

      {results.length > 0 && (
        <div onClick={() => handleClick()} className="search-results__see-more">
          <p className="search-results__see-more-text">See more results</p>
          <BiLinkExternal className="search-results__see-more-icon" size={18} />
        </div>
      )}
    </div>
  );
};

export default SearchResults;
