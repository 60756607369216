import { useContext } from "react";
import "./footer.scss";
import Wrapper from "../wrapper/Wrapper";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiFacebookCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { I18nContext } from "../../i18n";
import Logo from "../logo/Logo";

const Footer = () => {
  const { categories } = useContext(AppContext);
  const { translate, langCode } = useContext(I18nContext);

  return (
    <footer className="footer">
      <Wrapper>
        <nav className="footer__nav">
          <Logo />
          <div className="footer__categories">
            <p className="footer__title">{translate("footer").categories.toUpperCase()}</p>
            <ul>
              {categories
                ?.filter((item) => item.parent === 0)
                .map((cat, i) => (
                  <li className="footer__list-item" key={i}>
                    <Link
                      to={{
                        pathname: `/${langCode}/category/${cat.slug}`,
                        state: {
                          name: cat.name,
                        },
                      }}>
                      {translate("categories")?.[cat.slug]}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="footer__about">
            <Link to={`/${langCode}/about`} className="footer__title">
              {translate("footer").about.toUpperCase()}
            </Link>
          </div>
          <div className="footer__follow">
            <p className="footer__title">{translate("footer").follow.toUpperCase()}</p>
            <div className="footer__follow-icons">
              <a href="https://twitter.com/WebtopB" target="_blank" rel="noreferrer">
                <AiFillTwitterCircle className="footer__follow-icon footer__follow-icon--twitter" size={38} />
              </a>
              <a href="https://www.facebook.com/webtop.blog" target={"_blank"} rel="noreferrer">
                <RiFacebookCircleFill className="footer__follow-icon" size={40} />
              </a>
            </div>
          </div>
        </nav>
      </Wrapper>
    </footer>
  );
};

export default Footer;
