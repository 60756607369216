import { createContext, useContext, useEffect, useState } from 'react'
import { getCategories } from '../dataProvider/category'
import { getTags } from '../dataProvider/tags'
import { I18nContext } from '../i18n'

export const AppContext = createContext()

const { Provider } = AppContext

export const AppProvider = (props) => {
  const { langCode } = useContext(I18nContext)
  const [currentLanguage, setCurrentLanguage] = useState(langCode)
  const [categories, setCategories] = useState([])
  const [categorySelected, setCategorySelected] = useState()
  const [tags, setTags] = useState([])
  const [currentTag, setCurrentTag] = useState(undefined)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    try {
      getCategories(currentLanguage).then((res) => setCategories(res))
      getTags().then((res) => setTags(res))
    } catch (error) {
      console.error(error)
    }
  }, [currentLanguage])

  useEffect(() => {
    const tag = tags?.filter((item) => item.name === currentLanguage)?.[0]?.id
    setCurrentTag(tag)
  }, [currentLanguage, tags])

  const value = {
    setMenuIsOpen,
    menuIsOpen,
    currentTag,
    setCurrentLanguage,
    categories,
    setCategorySelected,
    currentLanguage,
    categorySelected,
    tags,
  }

  return <Provider value={value}>{props.children}</Provider>
}
